<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">客户关联信息</div>
            </div>
            <template>
                <avue-crud ref="crud" v-model="obj" :option="setData.tableOpt" :data="tableData" :row-style="rowStyle" :page.sync="page" @current-change="currentChange" @row-update="handleUpdate" @row-del="del(row, index, done)" @row-save="handleSave">
                    <template slot="menuLeft" style="width: calc(100% - 80px);">
                        <el-row :gutter="20" class="topFromSearch">
                            <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv"  style="display:none">
        <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
            <span id="CustormSpan">{{$t('Custorm')}}:</span>
            <el-input v-model="CustormVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
        </el-col>
    </el-col>
                            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="padding-top: 25px; text-align: right; align-self: flex-end;">
                                <el-button :title="$t('Search')" icon="iconfont icon-textile-sousuo" size="mini" type="primary" @click="searchFrom" style="display:none">  {{$t('Search')}}</el-button>
                                <el-button type="primary" icon="iconfont icon-textile-tianjia" size="mini" :title="$t('New')" plain @click="$refs.crud.rowAdd()"> {{$t('New')}}</el-button>
                            </el-col>
                            
                        </el-row>
                    </template>
                    <template slot-scope="{row,index,size,type}" slot="menu">
                        <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')" @click="$refs.crud.rowEdit(row,index)"></el-button>
                        <el-button icon="el-icon-delete-solid" type="text" size="medium" :title="$t('Delete')" @click="del(row,index)"></el-button>
                        <!--<el-button icon="iconfont icon-vip353" :class="row.status===3?'primary':'no'" type="text" size="medium" :title="$t('Delete')" @click="tracingPur(row,index,size,type)"></el-button>-->
                    </template>
                    <template slot-scope="{row,index,type}" slot="menuForm">
                        <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" v-if="type=='add'" @click="$refs.crud.rowSave()">{{$t("Hold")}}</el-button>
                        <el-button type="primary" icon="el-icon-circle-check" size="small" v-if="type=='edit'" @click="$refs.crud.rowUpdate()">{{$t("Hold")}}</el-button>
                        <el-button icon="el-icon-circle-close" size="small" @click="$refs.crud.closeDialog()">{{$t("Cancel")}}</el-button>
                    </template>
                </avue-crud>
            </template>
        </basic-container>
    </div>
</template>
<style>
</style>
<script>
    import { listInfo, delInfo, editInfo, addInfo, custormDDL } from "@/api/custorm/custormRelation";
    import { ProcessDropDownList, TracingDropDownList, TracingUnifiedIsBool} from "@/api/unified";

    export default {
        data() {
            return {
                paramCustormId: "",
                paramCustormName:"",
                obj: {},
                codeVal: "",
                nameVal: "",
                CustormVal: "",
                groupVal: {
                    ids: [],
                },
                locationVal: "",
                tableData: [],
                vendorData: [],
                custormData: [],
                vendorAddData: [],
                vendorvectionData: [],//客户类别
                colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev,next",
                    background: true,
                    pageSize: 10
                }
               
            }
        },
        computed: {
            setData() {
                return {
                    tableOpt: {
                        page: true,
                        refreshBtn: false,
                        emptyText: this.$t('No Data'),
                        menuTitle: this.$t('OPERATION'),
                        menuWidth: 140,
                        align: 'center',
                        columnBtn: false,
                        simplePage: false,
                        editTitle: this.$t('Edit'),
                        addTitle: this.$t('Add'),
                        addBtn: false,
                        editBtn: false,
                        delBtn: false,
                        header: true,
                       /* labelPosition:'top',*/
                        saveBtn: false,
                        updateBtn: false,
                        cancelBtn: false,
                        column: [
                            {
                                label:"客户名称",
                                prop: 'custormId',
                                disabled: true,
                                span: 24,
                                type: "select",
                                value: this.paramCustormId,
                                dicData: this.custormData,
                                rules: [{ required: true, message: this.$t('Required'), trigger: "blur" } ]
                            },
                            {
                                label: "关联客户",
                                prop: 'relationCustormId',
                                editDisabled: true,
                                placeholder: ' ',
                                span: 24,
                                type: "select",
                                dicData: this.custormData,
                                rules: [{ required: true, message: this.$t('Required'), trigger: "blur" }]
                            },
                            {
                                label: "客户类型",
                                prop: 'relationType',
                                type: "select",
                                filterable: true,
                                span: 24,
                               
                                dicData: [{
                                    label: 'typeA',
                                    value: 0
                                }, {
                                    label: 'typeB',
                                    value: 1
                                }, {
                                    label: 'typeC',
                                    value: 2
                                }],
                                placeholder: ' ',
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" }]
                            },
                            {
                                label: "状态",
                                prop: 'status',
                                type: "radio",
                                filterable: true,
                                span: 24,
                                value: 0,
                                dicData: [{
                                    label: '正常',
                                    value: 0
                                }, {
                                    label: '禁用',
                                    value: 1
                                }],
                                placeholder: ' ',
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" }]
                            }, {
                                label: "备注",
                                prop: 'remarks',
                                span: 24,
                                placeholder: ' ',
                                rules: [{ required: false, message: this.$t('Required'), trigger: "blur" }]
                            }

                        ]
                    }
                }
            }
        },
        created() {
            this.init()
            this.getList();
        },
        methods: {
            init() {
                this.paramCustormId = this.$route.query.id;
                this.paramCustormName = this.$route.query.custormName
                custormDDL().then(res => {
                    this.custormData = res.data;
                });
                
            },
            focusDiv(obj) {//搜索框获取焦点
                if (obj.currentTarget === undefined) {
                    obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                } else {
                    obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                }
            },//搜索框失去焦点
            blurDiv(obj) {
                if (obj.currentTarget === undefined) {
                    if (obj.value === "") {
                        obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                } else if (obj.currentTarget === null) {
                    if (this.CustormVal.ids.length === 0) {
                        document.querySelector("#CustormSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                    if (this.groupVal.ids.length === 0) {
                        document.querySelector("#allSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                } else {
                    if (obj.target.value == "") {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                }
            },
            Custormchang() {
                if (this.CustormVal.ids.length === 0) {
                    document.querySelector("#CustormSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                } else {
                    document.querySelector("#CustormSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                }
            },//
            allchang() {
                if (this.groupVal.ids.length === 0) {
                    document.querySelector("#allSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                } else {
                    document.querySelector("#allSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                }
            },//
            rowStyle({rowIndex }) {
                if (rowIndex % 2 === 0) {
                    return {
                        backgroundColor: '#F8F8F8'
                    }
                }
            },//切换分页
            currentChange(val) {
                this.page.currentPage = val;
                this.getList();
            },//搜索
            searchFrom() {
                this.getList();
            },//编辑数据
            handleUpdate(row, index, done) {
             
                editInfo(row).then(() => {
                    this.$message({ showClose: true, message: this.$t('UpdateSuccess'), type: "success" });
                    this.getList();
                    this.obj = {};
                })
                done();
            },
            //},//删除数据
            del(row, index, done) {
                this.$confirm(
                    this.$t('IsDel'), this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                    delInfo(row.custormId,row.relationCustormId).then(() => {
                        this.getList();
                        this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                        done();
                    })
                })
            },
            //显示数据    
            async getList() {
                await listInfo(this.paramCustormId,  this.page.currentPage, this.page.pageSize).then(res => {
                    var cardata = res.data.custormRelations;
                    this.tableData = cardata;
                    this.page.total = res.data.totalCount;
                    this.page.pageSize = res.data.pageSize;
                }).catch(erro => {
                    console.log(erro);
                })
            },
           
            //新增数据 
            handleSave(row, done) {
                addInfo(row).then(() => {
                    this.$message({ showClose: true, message: this.$t('AddSuccess'), type: "success" });
                    this.getList();
                    this.obj = {};
                    done();
                }).catch(() => { });
            },
        }
    }
</script>