import request from '@/router/axios';
import { pvhApiBase } from '@/config/env'
var pvhApiBaseCustorm = "http://localhost:5202/";

export const listInfo = (custormId, page, pageSize) => {
    return request({
        url: pvhApiBaseCustorm +"custorm/Custorm/GetCustormRelationList",
        method: "get",
        params: {
            "custormId": custormId,
            "page": page,
            "pageSzie": pageSize
        }
    })
}
export const addInfo = (row) => {
    return request({
        url: pvhApiBaseCustorm + "custorm/Custorm/PostCustormRelation",
        method: "post",
        data: {
            ...row
        }
    })
}

//
export const editInfo = (row) => {
    return request({
        url: pvhApiBaseCustorm + "custorm/Custorm/PutCustormRelation",
        method: "put",
        data: {
            ...row
        }
    })
}

//
export const delInfo = (custormId,relationCustormId) => {
    return request({
        url: pvhApiBaseCustorm + "custorm/Custorm/DeleteCustormRelation",
        method: "delete",
        params: {
            "custormId": custormId,
            "relationCustormId": relationCustormId,
        }
    })
}

export const custormDDL = () => {
    return request({
        url: pvhApiBaseCustorm + "custorm/Custorm/GetCustormDic",
        method: "get",
        params: {

        }
    })

}